import React from 'react'
import BigNumber from 'bignumber.js'
import { Menu as UikitMenu } from '@amtswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useAuth from 'hooks/useAuth'
import { usePriceJAMBusd } from 'state/hooks'
import useGetPriceData from 'hooks/useGetPriceData'
import tokens from 'config/constants/tokens'
import config from './config'

const Menu = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { isDark, toggleTheme } = useTheme()
  // const jamPriceUsd = usePriceJAMBusd()

  const bnbPriceData = useGetPriceData(tokens.wbnb.address[process.env.REACT_APP_CHAIN_ID])
  const jamPriceData = useGetPriceData(tokens.jam.address[process.env.REACT_APP_CHAIN_ID])
  const priceUsd = (bnbPriceData && jamPriceData) ? Number(new BigNumber(bnbPriceData.data.price).times(new BigNumber(jamPriceData.data.price_BNB)).toFixed(3))  : undefined
  // console.log(priceUsd);
  const { currentLanguage, setLanguage, t } = useTranslation()

  return (
    <UikitMenu
      account={account}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakePriceUsd={priceUsd}
      links={config(t)}
      // profile={{
      //   username: undefined,
      //   image: profile?.nft ? `/images/nfts/${profile.nft?.images.sm}` : undefined,
      //   showPip: !profile?.username,
      // }}
      {...props}
    />
  )
}

export default Menu
