import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 10,
    stakingToken: tokens.jam,
    earningToken: tokens.jam,
    contractAddress: {
      97: '0xeE65f40cd0dBE520F5B8a51D1b651A4bB4e0DCd8',
      56: '0x56644c6450471e4955f3553b00bc7b624042f9a3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    // tokenPerSecond: 0.052083333333333336,
    tokenPerSecond: 0.020340000000000000,
    sortOrder: 1,
    isFinished: false,
  },
  // {
  //   sousId: 11,
  //   stakingToken: tokens.busd,
  //   earningToken: tokens.jam,
  //   contractAddress: {
  //     97: '0x990b03A1264758756b58E871C4375be53344E5cB',
  //     56: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerSecond: 0.026041666666666668,
  //   sortOrder: 1,
  //   isFinished: false,
  // },
  // {
  //   sousId: 12,
  //   stakingToken: tokens.bnb,
  //   earningToken: tokens.jam,
  //   contractAddress: {
  //     97: '0x128098C10241DE049Da74156830dE4690C2e5d22',
  //     56: '',
  //   },
  //   poolCategory: PoolCategory.BINANCE,
  //   harvest: true,
  //   tokenPerSecond: 0.026041666666666668,
  //   sortOrder: 1,
  //   isFinished: false,
  // },
]

export default pools
