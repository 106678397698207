import { ButtonMenu, ButtonMenuItem } from '@amtswap/uikit'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyleCardNav = styled.div`
  max-width: 330px;
  margin: 30px auto 0;
`

function CardNav({ activeIndex = 0 }: { activeIndex?: number }) {
  return (
    <StyleCardNav>
      <ButtonMenu activeIndex={activeIndex} style={{width: '100%'}}>
        <ButtonMenuItem to='/farms' as={Link} style={{width: '50%'}}>Farms</ButtonMenuItem>
        <ButtonMenuItem to='/pools' as={Link} style={{width: '50%'}}>Pools</ButtonMenuItem>
      </ButtonMenu>
    </StyleCardNav>
  )
}

export default CardNav
