import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { getBep20Contract } from 'utils/contractHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import tokens from 'config/constants/tokens'
import farms from 'config/constants/farms'
import useWeb3 from './useWeb3'
import useRefresh from './useRefresh'

const useAmtPrice = () => {
  const [price, setPrice] = useState(BIG_ZERO)
  const web3 = useWeb3()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const lpAddr = farms[1].lpAddresses[process.env.REACT_APP_CHAIN_ID]
      const usdtContract = getBep20Contract(tokens.busd.address[process.env.REACT_APP_CHAIN_ID], web3)
      const amtContract = getBep20Contract(tokens.jam.address[process.env.REACT_APP_CHAIN_ID], web3)
      const bal1 = await usdtContract.methods.balanceOf(lpAddr).call()
      const bal2 = await amtContract.methods.balanceOf(lpAddr).call()
      setPrice(new BigNumber(bal1).div(bal2))
    }
    fetchBalance()
  }, [web3, fastRefresh])
  return price
}

export default useAmtPrice
