import React from 'react'
import { CardHeader, Heading, Text, Flex, Image, Button, useModal } from '@amtswap/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Pool } from 'state/types'
import { getFullDisplayBalance, getBalanceNumber, formatNumber } from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { PoolCategory } from 'config/constants/types'
import { useBusdPriceFromToken } from 'state/hooks'
import UnlockButton from 'components/UnlockButton'

import ApprovalAction from './CardActions/ApprovalAction'
import StakeActions from './CardActions/StakeActions'
import CollectModal from './Modals/CollectModal'


const Wrapper = styled(CardHeader)<{ isFinished?: boolean; background?: string; isPromotedPool?: boolean }>`
    // background: ${({
                        isFinished,
                        background,
                        theme,
                      }) => isFinished ? theme.colors.backgroundDisabled : theme.colors.gradients[background]};
  background: #373E45;
  border-radius: ${({ theme, isPromotedPool }) =>
          isPromotedPool ? '31px 31px 0 0' : `${theme.radii.card} ${theme.radii.card} 0 0`};
`

const StyledCardHeaderNew: React.FC<{
  pool: Pool
  earningTokenSymbol: string
  stakingTokenSymbol: string
  isAutoVault?: boolean
  isFinished?: boolean
  isStaking?: boolean
  isPromotedPool?: boolean
  account: string
}> = ({
        pool,
        earningTokenSymbol,
        stakingTokenSymbol,
        isFinished = false,
        isAutoVault = false,
        isStaking = false,
        isPromotedPool = false,
        account,
      }) => {
  const { t } = useTranslation()
  const poolImageSrc = isAutoVault
    ? `cake-cakevault.svg`
    : `${earningTokenSymbol}-${stakingTokenSymbol}.svg`.toLocaleLowerCase()
  const isCakePool = earningTokenSymbol === 'CAKE' && stakingTokenSymbol === 'CAKE'
  const background = isStaking ? 'bubblegum' : 'cardHeader'

  const getHeadingPrefix = () => {
    if (isAutoVault) {
      // vault
      return t('Auto')
    }
    if (isCakePool) {
      // manual cake
      return t('Manual')
    }
    // all other pools
    return t('Earn')
  }

  const getSubHeading = () => {
    if (isAutoVault) {
      return t('Automatic restaking')
    }
    if (isCakePool) {
      return t('Earn JAM, stake JAM')
    }
    return t('Stake %symbol%', { symbol: stakingTokenSymbol })
  }
  const { sousId, stakingToken, earningToken, harvest, poolCategory, userData } = pool
  const earnings = userData?.pendingReward ? new BigNumber(userData.pendingReward) : BIG_ZERO
  const earningTokenBalance = getBalanceNumber(earnings, earningToken.decimals)
  const formattedBalance = formatNumber(earningTokenBalance, 3, 3)
  const fullBalance = getFullDisplayBalance(earnings, earningToken.decimals)
  const hasEarnings = earnings.toNumber() > 0 && account
  const isCompoundPool = sousId === 0
  const isBnbPool = poolCategory === PoolCategory.BINANCE
  const earningTokenPrice = useBusdPriceFromToken(earningToken)
  const earningTokenPriceAsNumber = earningTokenPrice && earningTokenPrice.toNumber()
  const earningTokenDollarBalance =
    earningTokenPriceAsNumber &&
    getBalanceNumber(earnings.multipliedBy(earningTokenPriceAsNumber), earningToken.decimals)
  const earningsDollarValue = earningTokenDollarBalance && formatNumber(earningTokenDollarBalance)
  const allowance = userData?.allowance ? new BigNumber(userData.allowance) : BIG_ZERO
  const needsApproval = !allowance.gt(0) && !isBnbPool
  const isLoading = !userData
  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO
  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const isStaked = stakedBalance.gt(0)


  const [onPresentCollect] = useModal(
    <CollectModal
      formattedBalance={formattedBalance}
      fullBalance={fullBalance}
      earningToken={earningToken}
      earningsDollarValue={earningsDollarValue}
      sousId={sousId}
      isBnbPool={isBnbPool}
      isCompoundPool={isCompoundPool}
    />,
  )

  const Approval = function() {
    return (
      needsApproval ? (
        <ApprovalAction pool={pool} isLoading={isLoading} />
      ) : (
        <StakeActions
          isLoading={isLoading}
          pool={pool}
          stakingTokenBalance={stakingTokenBalance}
          stakedBalance={stakedBalance}
          isBnbPool={isBnbPool}
          isStaked={isStaked}
        />
      )
    )
  }

  return (
    <Wrapper isPromotedPool={isPromotedPool} isFinished={isFinished}>
      <Flex alignItems='center' flexDirection='column' style={{ width: '100%' }}>
        <Flex flexDirection='column' style={{ width: '100%' }}>
          <Heading color={isFinished ? 'textDisabled' : 'head'} scale='lg' style={{ fontSize: '24px !important' }}>
            {`${getHeadingPrefix()} ${earningTokenSymbol}`}
          </Heading>
        </Flex>
        <Flex flexDirection='row' justifyContent='space-between' alignItems='center'
              style={{ width: '100%', margin: '20px 0' }}>
          <Image src={`/images/pools/${poolImageSrc}`} alt={earningTokenSymbol} width={64} height={64} />
          {/* <Image src={`/images/pools/${poolImageSrc}`} alt={earningTokenSymbol} width={64} height={64} /> */}
          <Button disabled={!hasEarnings} onClick={onPresentCollect}>
            {isCompoundPool ? t('Collect') : t('Harvest')}
          </Button>
        </Flex>
      </Flex>

      {
        !account ? (
          <>
            <UnlockButton width='100%' />
          </>
        ) : (
          Approval()
        )
      }
    </Wrapper>
  )
}

export default StyledCardHeaderNew
