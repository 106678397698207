import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Dashboard'),
    icon: 'Dashboard',
    href: 'https://nft.amateras.io/#/app'
  },
  {
    label: t('Swap'),
    icon: 'Swap',
    href: 'https://swap.amateras.io/',
  },
  {
    label: t('Stake'),
    icon: 'Stake',
    href: '/farms'
  },
  {
    label: t('Mining Pool'),
    icon: 'MiningPool',
    href: 'https://nft.amateras.io/#/app/MiningPool',
  },
  {
    label: t('Farming Pool'),
    icon: 'FarmingPool',
    href: 'https://nft.amateras.io/#/app/FarmingPool',
  },
  {
    label: t('Bonuses Pool'),
    icon: 'BonusesPool',
    href: 'https://nft.amateras.io/#/app/BonusesPool'
  },
  {
    label: t('Referral'),
    icon: 'Referral',
    href: 'https://nft.amateras.io/#/app/Referral'
  },
  {
    label: t('NFT Market'),
    icon: 'nft_market',
    href: 'https://nft.amateras.io/#/app/NFTMarket'
  },
  {
    label: t('OMIKUJI'),
    icon: 'Game',
    items: [
      {
        label: t('Draw OMIKUJI'),
        href: 'https://nft.amateras.io/#/app/Game/BuyBox',
      },
      {
        label: t('My OMIKUJI（NFT）'),
        href: 'https://nft.amateras.io/#/app/Game/MyNFT',
      },
      {
        label: t('DISPLAY'),
        href: 'https://nft.amateras.io/#/app/Game/Display',
      },
      {
        label: t('COMBINE GUIDE'),
        href: 'https://nft.amateras.io/#/app/Game/CombineGuide',
      }
    ]
  },
  // {
  //   label: 'FAQ',
  //   icon: 'FAQ',
  //   href: '',
  // },
]

export default config
