import BigNumber from 'bignumber.js'
import { CAKE_PER_BLOCK, YEAR_SECOND } from 'config'

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerSecond Amount of new JAM allocated to the pool for each new SECOND
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerSecond: number,
): number => {
  console.log('--------------->>>>>', stakingTokenPrice, rewardTokenPrice, totalStaked, tokenPerSecond)
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerSecond).times(YEAR_SECOND)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get farm APR value in %
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @returns
 */
export const getFarmApr = (cakePriceUsd: BigNumber, poolLiquidityUsd: BigNumber, tokenPerSecond): number => {
  const yearlyCakeRewardAllocation = YEAR_SECOND.times(tokenPerSecond)
  const apr = yearlyCakeRewardAllocation.times(cakePriceUsd).div(poolLiquidityUsd).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

export default null
