import React from 'react'
import styled from 'styled-components'
// import { Spinner } from '@amtswap/uikit'
import { Image } from '@amtswap/uikit'
import Page from './layout/Page'


const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledImage = styled(Image)`
  margin-left: auto;
  margin-right: auto;
  // margin-top: 58px;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      {/* <Spinner /> */}
      <StyledImage src="/images/loading.gif" width={640} height={640} />
    </Wrapper>
  )
}

export default PageLoader
