import { ethers } from 'ethers'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import web3NoAccount from 'utils/web3'
import { poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'
import MultiCallAbi from 'config/abi/Multicall.json'

// Addresses
import {
  getAddress,
  getJAMAddress,
  getLotteryAddress,
  getLotteryTicketAddress,
  getMulticallAddress,
  getContractV2
} from 'utils/addressHelpers'

// ABI'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import jamAbi from 'config/abi/jam.json'
import lotteryAbi from 'config/abi/lottery.json'
import lotteryTicketAbi from 'config/abi/lotteryNft.json'
import jampoolAbi from 'config/abi/AMT-Pool.json'
import JamCakeTokenLp from 'config/abi/JamCakeTokenLp.json'
import jambnbpoolAbi from 'config/abi/AMT-BNB-Pool.json'

const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount
  return new _web3.eth.Contract(abi as unknown as AbiItem, address)
}
export const getBep20Contract = (address: string, web3?: Web3) => {
  return getContract(bep20Abi, address, web3)
}
export const getErc721Contract = (address: string, web3?: Web3) => {
  return getContract(erc721Abi, address, web3)
}
export const getLpContract = (address: string, web3?: Web3) => {
  return getContract(lpTokenAbi, address, web3)
}
export const getJamPoolContract = (id: number, web3?: Web3) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  if (config.poolCategory === PoolCategory.POOL_LP){
    return getContract(JamCakeTokenLp, getAddress(config.contractAddress), web3)
  }
  if(config.poolCategory === PoolCategory.BINANCE){
    return getContract(jambnbpoolAbi, getAddress(config.contractAddress), web3)
  }

  return getContract(jampoolAbi, getAddress(config.contractAddress), web3)
}

export const getJamFarmContract = (address: string, web3?: Web3) => {
  return getContract(jampoolAbi, address, web3)
}

export const getJAMContract = (web3?: Web3) => {
  return getContract(jamAbi, getJAMAddress(), web3)
}

export const getLotteryContract = (web3?: Web3) => {
  return getContract(lotteryAbi, getLotteryAddress(), web3)
}
export const getLotteryTicketContract = (web3?: Web3) => {
  return getContract(lotteryTicketAbi, getLotteryTicketAddress(), web3)
}

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContractV2(MultiCallAbi, getMulticallAddress(), signer)
}
