export default {
  lottery: {
    97: '',
    56: '',
  },
  lotteryNFT: {
    97: '',
    56: '',
  },
  multiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0xff0997392A63Ca7e5eaB82F22077eF848cb468e2',
  },
}
