import BigNumber from 'bignumber.js'
import poolsConfig from 'config/constants/pools'
import jampoolABI from 'config/abi/jampool.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'

export const fetchPoolsTotalStaking = async () => {
  const nonBnbPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'BNB')
  const bnbPool = poolsConfig.filter((p) => p.stakingToken.symbol === 'BNB')

  const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'totalSupply',
      params: [],
    }
  })

  const callsBnbPools = bnbPool.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'totalSupply',
      params: [],
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(jampoolABI, callsNonBnbPools)
  const bnbPoolsTotalStaked = await multicall(jampoolABI, callsBnbPools)

  return [
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
    })),
    ...bnbPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON(),
    })),
  ]
}

export default fetchPoolsTotalStaking