import tokens from './tokens'
import { FarmConfig, FarmLPCategory } from './types'

const farms: FarmConfig[] = [
  {
    pid: 251,
    lpSymbol: 'AMT-BNB LP',
    lpAddresses: {
      // AMT-BNB LP
      97: '0x15578ab3e1d27cebb19049e9343ef503d8e197f7',
      56: '0x90Ed786E2e88ed2CBf272720cA5F3B095190c74A',
    },
    farmAddresses: {
      // AMT-BNB-Pool
      97: '0x128098C10241DE049Da74156830dE4690C2e5d22',
      56: '0x1985f021a6e1d38030e70e6090a42d17b04fc76e',
    },
    earningTokens: [
      tokens.jam,
    ],
    lpCategory: FarmLPCategory.AMT,
    token: tokens.jam,
    quoteToken: tokens.wbnb,
    tokenPerSecond: 0.010170000000000000,
  },
  {
    pid: 252,
    lpSymbol: 'AMT-BUSD LP',
    lpAddresses: {
      // BUSD-AMT LP
      97: '0x4546fe3e1d972c7044533f16634a79b7f278aa60',
      56: '0xAc8ae2fF9C4632d9C0c9CAd5b99244F4233292Db',
    },
    farmAddresses: {
      97: '0x990b03A1264758756b58E871C4375be53344E5cB',
      56: '0x13b86bbbb411117d4d8e6761ba05a0d889fb3a8d',
    },
    earningTokens: [
      tokens.jam,
    ],
    lpCategory: FarmLPCategory.AMT,
    token: tokens.busd,
    quoteToken: tokens.jam,
    tokenPerSecond: 0.010170000000000000,
  },

]

export default farms
