import React from 'react'
import { Flex, useModal, Skeleton, useTooltip, Text } from '@amtswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import { getPoolApr } from 'utils/apr'
import { tokenEarnedPerThousandDollarsCompounding, getRoi } from 'utils/compoundApyHelpers'
import { useBusdPriceFromToken } from 'state/hooks'
import Balance from 'components/Balance'
import ApyCalculatorModal from 'components/ApyCalculatorModal'
import { Pool } from 'state/types'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { BASE_EXCHANGE_URL } from 'config'
import useAmtPrice from 'hooks/useAmtPrices'

interface AprRowProps {
  pool: Pool
  isAutoVault?: boolean
  compoundFrequency?: number
  performanceFee?: number
  account?: string
}

const AprRow: React.FC<AprRowProps> = ({ pool, isAutoVault = false, compoundFrequency = 1, performanceFee = 0, account = '' }) => {
  const { t } = useTranslation()
  const amtPrice = useAmtPrice()

  const { stakingToken, earningToken, totalStaked, isFinished, tokenPerSecond, userData  } = pool
  const tooltipContent = isAutoVault
    ? t('APY includes compounding, APR doesn’t. This pool’s JAM is compounded automatically, so we show APY.')
    : t('This pool’s rewards aren’t compounded automatically, so we show APR')

  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, { placement: 'bottom-end' })

  const earningTokenPrice = useBusdPriceFromToken(earningToken)
  const earningTokenPriceAsNumber = earningTokenPrice && earningTokenPrice.toNumber()
  // const stakingTokenPrice = useBusdPriceFromToken(stakingToken)
  // const stakingTokenPriceAsNumber = stakingTokenPrice && stakingTokenPrice.toNumber()
  const stakingTokenPriceAsNumber = amtPrice && amtPrice.toNumber()

  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const isStaked = stakedBalance.gt(0)
  const stakedTokenBalance = getBalanceNumber(stakedBalance, stakingToken.decimals)

  const apr = getPoolApr(
    stakingTokenPriceAsNumber,
    stakingTokenPriceAsNumber,
    // earningTokenPriceAsNumber,
    getBalanceNumber(totalStaked, stakingToken.decimals),
    tokenPerSecond,
  )

  // console.log('===============>>>>>', apr, pool)
  // special handling for tokens like tBTC or BIFI where the daily token rewards for $1000 dollars will be less than 0.001 of that token
  const isHighValueToken = Math.round(earningTokenPriceAsNumber / 1000) > 0
  const roundingDecimals = isHighValueToken ? 4 : 2

  const earningsPercentageToDisplay = () => {
    if (isAutoVault) {
      const oneThousandDollarsWorthOfToken = 1000 / earningTokenPriceAsNumber
      const tokenEarnedPerThousand365D = tokenEarnedPerThousandDollarsCompounding({
        numberOfDays: 365,
        farmApr: apr,
        tokenPrice: earningTokenPriceAsNumber,
        roundingDecimals,
        compoundFrequency,
        performanceFee,
      })
      return getRoi({
        amountEarned: tokenEarnedPerThousand365D,
        amountInvested: oneThousandDollarsWorthOfToken,
      })
    }
    return apr
  }

  const apyModalLink =
    stakingToken.address &&
    `${BASE_EXCHANGE_URL}/#/swap?outputCurrency=${stakingToken.address[process.env.REACT_APP_CHAIN_ID]}`

  const [onPresentApyModal] = useModal(
    <ApyCalculatorModal
      tokenPrice={earningTokenPriceAsNumber}
      apr={apr}
      linkLabel={t('Get %symbol%', { symbol: stakingToken.symbol })}
      linkHref={apyModalLink || BASE_EXCHANGE_URL}
      earningTokenSymbol={earningToken.symbol}
      roundingDecimals={isHighValueToken ? 4 : 2}
      compoundFrequency={compoundFrequency}
      performanceFee={performanceFee}
    />,
  )

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        {tooltipVisible && tooltip}
        <Text ref={targetRef}>{isAutoVault ? `${t('APY')}:` : `${t('APR')}:`}</Text>
        {isFinished || !apr ? (
          <Skeleton width="82px" height="32px" />
        ) : (
          <Flex alignItems="center">
            <Balance
              fontSize="16px"
              isDisabled={isFinished}
              value={earningsPercentageToDisplay()}
              decimals={2}
              unit="%"
              bold
            />
            {/* <IconButton onClick={onPresentApyModal} variant="text" scale="sm">
              <CalculateIcon color="textSubtle" width="18px" />
            </IconButton> */}
          </Flex>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mt="10px">
        <Text fontSize="14px">{ t('Your Stake') }:</Text>
        <Flex alignItems="center">
          {account ? (
            <Balance bold fontSize="20px" decimals={3} value={stakedTokenBalance} />
          ) : (
            <Skeleton width="82px" height="32px" />
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default AprRow
