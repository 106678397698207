const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
    decimals: 18,
  },
  jam: {
    symbol: 'AMT',
    address: {
      56: '0x4ce5f6bf8e996ae54709c75865709aca5127dd54',
      97: '0x52713665be556022e4953a4d5cfc9c75862c7791',
    },
    decimals: 18,
    projectLink: 'https://www.bscscan.com/address/0x56644c6450471e4955f3553b00bc7b624042f9a3',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  wbnb: {
    symbol: 'wBNB',
    address: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
}

export default tokens
