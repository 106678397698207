import { useMemo } from 'react'
import useWeb3 from 'hooks/useWeb3'
import {
  getBep20Contract,
  getJAMContract,
  getLotteryContract,
  getLotteryTicketContract,
  getErc721Contract,
  getJamPoolContract,
  getJamFarmContract,
} from 'utils/contractHelpers'
import { getAddress } from 'utils/addressHelpers'
import { Address } from 'config/constants/types'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getBep20Contract(address, web3), [address, web3])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getErc721Contract(address, web3), [address, web3])
}

export const useJAM = () => {
  const web3 = useWeb3()
  return useMemo(() => getJAMContract(web3), [web3])
}

export const useLottery = () => {
  const web3 = useWeb3()
  return useMemo(() => getLotteryContract(web3), [web3])
}

export const useLotteryTicket = () => {
  const web3 = useWeb3()
  return useMemo(() => getLotteryTicketContract(web3), [web3])
}


export const useJAMpool = (id) => {
  const web3 = useWeb3()
  return useMemo(() => getJamPoolContract(id, web3), [id, web3])
}
export const useJAMfarm = (address: Address) => {
  const web3 = useWeb3()
  const addressStr = getAddress(address)
  return useMemo(() => getJamFarmContract(addressStr, web3), [addressStr, web3])
}
